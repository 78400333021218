import React from 'react';
import Helmet from 'react-helmet';

const RichSnippedWebPage = ({ title, description, image, lang, author, siteUrl, buildTime }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    name: title,
    inLanguage: lang,
    mainEntityOfPage: siteUrl,
    description,
    copyrightYear: new Date().getFullYear(),
    headline: title,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    creator: {
      '@type': 'Organization',
      url: 'https://www.timesharescam.com',
      name: 'Mexican Timeshare Solutions',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.timesharescam.com/icons/icon-512x512.png',
      },
    },
    publisher: {
      '@type': 'Organization',
      url: 'https://www.timesharescam.com',
      name: 'Mexican Timeshare Solutions',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.timesharescam.com/icons/icon-512x512.png',
      },
    },
    datePublished: '2010-01-18T10:30:00-06:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default RichSnippedWebPage;
