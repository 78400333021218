import React from 'react';
import { StarFilled } from '@ant-design/icons';
import '../styles/components/_components.rating.scss';

const Rating = ({ value }) => {
  return (
    <span className="c-rating">
      <StarFilled />
      <StarFilled />
      <StarFilled />
      <StarFilled />
      <StarFilled />
      <span className="c-rating__value" style={{ width: `${value}%` }}>
        <StarFilled />
        <StarFilled />
        <StarFilled />
        <StarFilled />
        <StarFilled />
      </span>
    </span>
  );
};

export default Rating;
