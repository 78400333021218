import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FacebookFilled, YoutubeFilled, TwitterOutlined } from '@ant-design/icons';
import useI18n from '../utils/useI18n';
import { ReactComponent as WhatsAppLogo } from '../images/whatsapp.svg';
import { ReactComponent as TelegramLogo } from '../images/telegram.svg';
import ArticleMini from './ArticleMini';
import Link from './Link';

import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as USA } from '../images/usa.svg';
import { ReactComponent as MX } from '../images/mexico.svg';
import '../styles/components/_components.footer.scss';
import '../styles/components/_components.menuFooter.scss';

const Footer = ({ switchLang, onlyCopy }) => {
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
    query ArticlesQuery {
      allArticle(limit: 3, sort: { fields: Article___created, order: DESC }, skip: 0) {
        nodes {
          id
          Article {
            id
            slug
            titulo
            comments
            rating_value
            rating_count
          }
          Foto {
            url
            big
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 65, maxHeight: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const phone = '523332396589';
  const whatsAppMessage = encodeURI(t('whatsApp.message'));
  const telegramUsername = 'MexicanTimeshareSolutions';

  return (
    <footer>
      {!onlyCopy && (
        <div className="c-section c-section--white c-section__left u-align-left">
          <div className="c-section__content o-wrapper">
            <div className="o-layout">
              <div className="o-layout__item u-4/12@desktop u-12/12@tablet">
                <h4>{t('footer.menu.title', 'Menu')}</h4>
                <ul className="c-menuFooter">
                  <li className="c-menuFooter__item">
                    <Link to="/" activeClassName="current">
                      {t('footer.menu.home')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.about')}/`} activeClassName="current">
                      {t('footer.menu.about', 'About Mexican Timeshare Solutions')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.blog')}/`} activeClassName="current" partiallyActive>
                      {t('footer.menu.blog', 'Articles about canceling timeshare')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.blacklist')}/`} activeClassName="current" partiallyActive>
                      {t('footer.menu.blacklist', 'Timeshare complaints')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.faqs')}/`} activeClassName="current">
                      {t('footer.menu.faqs', 'Timeshare cancellation')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link
                      to={`${t('url.testimonials')}/`}
                      activeClassName="current"
                      partiallyActive
                    >
                      {t('footer.menu.testimonials', 'Timeshare cancellation testimonials')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.tips')}/`} activeClassName="current">
                      {t('footer.menu.tips', 'Tips to avoid timeshare fraud')}
                    </Link>
                  </li>
                  <li className="c-menuFooter__item">
                    <Link to={`${t('url.contact')}/`} activeClassName="current">
                      {t('footer.menu.contact')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="o-layout__item u-6/12@tablet u-4/12@desktop">
                <h4>{t('footer.articles.title', 'Most read articles')}</h4>
                <ul className="o-list-bare">
                  {data.allArticle.nodes.map((article) => (
                    <li className="o-list-bare__item" key={article.id}>
                      <ArticleMini data={article} />
                    </li>
                  ))}
                </ul>
                <div className="c-footer__blogLink">
                  <Link to={`${t('url.blog')}/`} className="c-button">
                    {t('footer.articles.readMore', 'Read our blog')}
                  </Link>
                </div>
              </div>
              <div className="o-layout__item u-6/12@tablet u-4/12@desktop">
                <Link to="/" className="c-footer__mts">
                  <Logo className="c-footer__mtsLogo" />
                  Mexican Timeshare Solutions
                </Link>
                <dl className="c-footer__contactInfo">
                  <dt>{t('footer.contact.tollFree')}:</dt>
                  <dd>
                    <a href="tel:888-275-3595">+1 888-275-3595</a>
                  </dd>
                  <dt>{t('footer.contact.phone')}:</dt>
                  <dd>
                    <a href="tel:714-277-3662">+1 714-277-3662</a>
                  </dd>
                  <dt>{t('footer.contact.phoneMx')}:</dt>
                  <dd>
                    <a href="tel:334-162-5467">+52 334-162-5467</a>
                  </dd>
                </dl>
                <strong>{t('footer.contact.email')}</strong>
                <div className="c-footer__socials">
                  <a
                    href="https://www.facebook.com/Mexican-Timeshare-Solutions-Timeshare-Cancellation-148325441949169/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookFilled />
                  </a>
                  <a
                    href="https://www.youtube.com/user/MTimeshareSolutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeFilled />
                  </a>
                  <a
                    href="https://twitter.com/MxTSolutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterOutlined />
                  </a>
                  <a
                    className="c-footer__whatsApp"
                    href={`https://api.whatsapp.com/send?phone=${phone}&text=${whatsAppMessage}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WhatsAppLogo />
                    {t`footer.whatsApp`}
                  </a>
                  <a
                    className="c-footer__telegram"
                    href={`https://t.me/${telegramUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TelegramLogo />
                    {t`footer.telegram`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="o-wrapper u-margin-top-large c-footer__copyright">
        <p>
          {t('footer.copyright', { year: new Date().getFullYear() })}{' '}
          <Link to={t('url.privacy')}>{t('footer.copyright.privacy', 'Privacy Policies')}</Link>
          {' | '}
          <Link to={t('url.disclaimer')}>{t('footer.copyright.disclaimer', 'Disclaimer')}</Link>
          {' | '}
          {/*
          <Link to={t('url.sitemap')}>
            {t('footer.copyright.sitemap', 'Site Map')}
          </Link>{' | '}
          */}
          {switchLang && (
            <a href={switchLang.url} className="c-footer__switchLang">
              {switchLang.lang === 'es' ? <USA /> : <MX />}
              <span>{switchLang.label}</span>
            </a>
          )}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
