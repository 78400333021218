import IntlMessageFormat from 'intl-messageformat';
import useLocale from './useLocale';
import useSiteMetadata from './useSiteMetadata';

export const replaceValues = (msg, values) => {
  return Object.keys(values).reduce((result, key) => {
    return result.replace(`{${key}}`, values[key]);
  }, msg);
};

export default function useI18n() {
  const locale = useLocale();
  const siteMetadata = useSiteMetadata();
  const translations = JSON.parse(siteMetadata.translations);
  return {
    t: (id, values, simple = true) => {
      try {
        const msg = translations[id];
        if (!simple) {
          return new IntlMessageFormat(msg, locale).format(values);
        }

        if (values) {
          return replaceValues(msg, values);
        }
        return msg;
      } catch (e) {
        console.log(id, e);
        return id;
      }
    },
    locale,
    messages: translations,
  };
}
