import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';

export default function useLocale() {
  const data = useStaticQuery(
    graphql`
        query Locale {
            site {
                siteMetadata {
                    lang
                }
            }
        }
    `,
  );
  return get(data, 'site.siteMetadata.lang');
}
