import React from 'react';
// import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';

const Link = ({
  to,
  activeClassName,
  activeStyle,
  partiallyActive,
  replace,
  state,
  className,
  ...props
}) => {
  const { pathname } = useLocation();
  const classNames = [className];
  if (
    pathname.replace(/\/$/, '') === to.replace(/\/$/, '') ||
    (partiallyActive && pathname.match(`^${to.replace(/\/$/, '')}`))
  ) {
    classNames.push(activeClassName);
  }
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={to} className={classNames.join(' ')} {...props} />;
  /*return (
    <GatsbyLink
      {...{
        to,
        activeClassName,
        partiallyActive,
        replace,
        state,
        className,
        ...props,
      }}
    />
  );*/
};

export default Link;
