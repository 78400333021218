import React from 'react';
import { ReactComponent as WhatsAppLogo } from '../images/whatsapp.svg';
import useI18n from '../utils/useI18n';
import '../styles/components/_components.whatsAppLink.scss';

const WhatsAppLink = () => {
  const { t } = useI18n();
  const phone = '523332396589';
  const message = encodeURI(t('whatsApp.message'));
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${phone}&text=${message}`}
      className="c-whatsAppLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      <WhatsAppLogo />
    </a>
  );
};

export default WhatsAppLink;
