import React, { useState } from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { graphql, useStaticQuery } from 'gatsby';
import StopPropagation from './StopPropagation';
import { ReactComponent as Logo } from '../images/logo.svg';

const Search = () => {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    googleSearchId
                }
            }
        }
    `,
  );

  const [visible, setVisible] = useState(false);
  const toggleOpen = () => {
    setVisible(!visible);
  };
  return (
    <StopPropagation className="Search">
      <a href="#" onClick={toggleOpen}><SearchOutlined /></a>
      {visible && (
        <div className="Search__container">
          <div className="Search__overlay" onClick={toggleOpen} />
          <div className="Search__wrapper animate__fadeInDown">
            <div className="Search__title">
              <span className="c-menu__logo">
                <Logo />
              </span>
              <h3>Search</h3>
              <div className="Search__close" onClick={toggleOpen}>
                <CloseOutlined />
              </div>
            </div>
            <script
              async
              src={`https://cse.google.com/cse.js?cx=${site.siteMetadata.googleSearchId}`}
              data-enableAutoComplete="true"
            />
            <div className="gcse-searchbox" data-enableAutoComplete="true" />
            <div className="gcse-searchresults" />
          </div>
        </div>
      )}
    </StopPropagation>
  );
};

export default Search;
