import React from 'react';
import { ReactComponent as TelegramLogo } from '../images/telegram.svg';
import useI18n from '../utils/useI18n';
import '../styles/components/_components.telegramLink.scss';

const WhatsAppLink = () => {
  const { t } = useI18n();
  const username = 'MexicanTimeshareSolutions';
  //const message = encodeURI(t('whatsApp.message'));
  return (
    <a
      href={`https://t.me/${username}`}
      className="c-telegramLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TelegramLogo />
    </a>
  );
};

export default WhatsAppLink;
