/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';
import RichSnippedOrganization from './RichSnippedOrganization';
import RichSnippedWebPage from './RichSnippedWebPage';
import Facebook from './Facebook';
import Twitter from './Twitter';

function SEO({
  description,
  lang,
  meta,
  title,
  keywords,
  image: metaImage,
  pathname,
  webpage = false,
  organization = false,
  article,
  breadCrumbs = [],
  jsonLD = [],
  altTitle,
  amp = false,
  noCanonical = false,
  children,
}) {
  const { site, defaultImage } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime
          siteMetadata {
            title
            description
            author
            lang
            siteUrl
          }
        }
        defaultImage: allImageSharp(filter: { fixed: { originalName: { eq: "share-mts.png" } } }) {
          edges {
            node {
              fixed(pngQuality: 80, width: 1200, height: 630) {
                src
                width
                height
              }
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const image = metaImage || get(defaultImage, 'edges.0.node.fixed');
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  const itemListElement = [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': site.siteMetadata.siteUrl,
        name: 'Home',
      },
    },
  ];
  if (pathname !== '/' && !breadCrumbs.length) {
    itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `${site.siteMetadata.siteUrl}${pathname}`,
        name: altTitle || title,
      },
    });
  }

  return (
    <>
      <Helmet>
        <html lang={site.siteMetadata.lang || lang} {...(amp ? { '⚡': true } : {})} />
        <title>{`${title || site.siteMetadata.title}`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="image" content={`${site.siteMetadata.siteUrl}${image.src}`} />
        {meta.length &&
          meta.map((item) => <meta name={item.name} content={item.content} key={item.name} />)}
        {!noCanonical && canonical && <link rel="canonical" href={canonical} />}
        {jsonLD.map((item) => (
          <script type="application/ld+json" key={item.toString()}>
            {JSON.stringify(item)}
          </script>
        ))}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: itemListElement.concat(
              breadCrumbs.map((item, index) => ({
                '@type': 'ListItem',
                position: index + 2,
                item: {
                  '@id': item.url,
                  name: item.name,
                },
              })),
            ),
          })}
        </script>
        {children}
      </Helmet>
      <Facebook
        desc={description}
        image={`${site.siteMetadata.siteUrl}${image.src}`}
        title={title}
        type={article ? 'article' : 'website'}
        url={canonical}
        locale={site.siteMetadata.lang || lang}
        name="Mexican-Timeshare-Solutions-Timeshare-Cancellation"
      />
      <Twitter
        title={title}
        image={`${site.siteMetadata.siteUrl}${image.src}`}
        desc={description}
        username="@MxTSolutions"
      />
      {organization && <RichSnippedOrganization />}
      {webpage && (
        <RichSnippedWebPage
          title={title}
          description={metaDescription}
          image={`${site.siteMetadata.siteUrl}${image.src}`}
        />
      )}
    </>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  keywords: '',
  pathname: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
};

export default SEO;
