import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';

import useI18n from '../utils/useI18n';
import Menu from './Menu';
import WhatsAppLink from './WhatsAppLink';
import Link from './Link';
import { ReactComponent as Logo } from '../images/logo.svg';

import '../styles/components/_components.header.scss';
import TelegramLink from './TelegramLink';

const Header = ({ noMenu }) => {
  const { t } = useI18n();
  return (
    <header className="c-header">
      <div className="c-header__content">
        <span className="c-header__bg" />
        <a
          className="c-header__callUs"
          href={`tel:${t`phone`}`}
          onClick={() => {
            if (window.gtag) {
              window.gtag('event', 'call', {
                event_category: 'header',
                event_label: 'barra',
                value: 1,
              });
            }
          }}
        >
          <PhoneOutlined /> {t`header.callUsToday`}
        </a>
        <nav className="o-wrapper c-header__nav">
          <Link to="/" className="c-menu__logo">
            <Logo />
          </Link>
          {!noMenu ? <Menu /> : <div className="c-menu" />}
          <TelegramLink />
          <WhatsAppLink />
          {/*
          <a
            onClick={() => {
              if (window.gtag) {
                window.gtag('event', 'wapp', {
                  event_category: 'mandar msj',
                  event_label: 'whatsapp web',
                  value: 1,
                });
              }
            }}
            href="https://www.bbb.org/us/ca/hayward/profile/timeshare-advocates/mexican-timeshare-solutions-1116-383105"
            target="_blank"
            rel="noreferrer nofollow"
            className="c-header__bbb"
          >
            <BBBLogo />
          </a>
          */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
