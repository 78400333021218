import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import Img from 'gatsby-image';
import useI18n from '../utils/useI18n';
import Link from './Link';

import Rating from './Rating';
import '../styles/components/_components.articleMini.scss';

const ArticleMini = ({ data }) => {
  const { Article, featuredImg } = data;
  const { t } = useI18n();
  return (
    <div className="c-articleMini o-media o-media--small">
      <div className="o-media__img c-articleMini__img">
        {featuredImg && (
          <Img fluid={featuredImg.childImageSharp.fluid} alt={Article.titulo} fadeIn={false} />
        )}
      </div>
      <div className="o-media__body">
        <h4 className="c-articleMini__title">
          <Link to={`${t('url.blog')}/${Article.id}-${Article.slug}/`}>{Article.titulo}</Link>
        </h4>
        <div className="c-articleMini__meta">
          <Rating value={parseFloat(Article.rating_value || 4.5) * 20} />

          <span className="c-articleMini__comments">
            <MessageOutlined /> {t('commentsTotal', { count: Article.comments }, false)}
          </span>
        </div>
      </div>
      <Link to={`${t('url.blog')}/${Article.id}-${Article.slug}/`} className="c-blockLink" />
    </div>
  );
};

export default ArticleMini;
