/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const StopPropagation = ({
  children,
  node: Node = 'div',
  preventDefault,
  event = 'onClick',
  ...props
}) => {
  const callback = e => {
    if (preventDefault) e.preventDefault();
    e.stopPropagation();
  };
  const events = {};
  event.split(',').forEach(ev => {
    events[ev.trim()] = callback;
  });

  return <Node {...{ ...events, ...props }}>{children}</Node>;
};

StopPropagation.defaultProps = {
  event: 'onClick',
  preventDefault: false,
  node: 'div',
};

export default StopPropagation;
