import React from 'react';
import Helmet from 'react-helmet';
import useI18n from '../../utils/useI18n';

const RichSnippedOrganization = () => {
  const { t } = useI18n();
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.timesharescam.com',
    name: 'Mexican Timeshare Solutions',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+1 888-275-3595',
        contactType: 'Customer Support',
        contactOption: 'TollFree',
        availableLanguage: 'English',
        areaServed: 'US',
        email: 'info@timesharescam.com',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+52 334-162-5467',
        contactType: 'Soporte al cliente',
        availableLanguage: 'Spanish',
        areaServed: 'MX',
        email: 'info@timesharescam.com',
      },
    ],
    logo: {
      '@type': 'ImageObject',
      url: 'https://www.timesharescam.com/icons/icon-512x512.png',
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://www.timesharescam.com/icons/icon-512x512.png',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 5,
      ratingCount: 1120,
    },
    address: '8383 Wilshire Blvd Suite 800, Beverly Hills, CA 90211, (USA)',
    email: 'info@timesharescam.com',
    sameAs: [
      'https://twitter.com/MxTSolutions',
      'https://www.instagram.com/cancelartiemposcompartidos/',
      'https://www.pinterest.com/mtsolutions/',
      'https://www.youtube.com/MTimeshareSolutions',
    ],
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default RichSnippedOrganization;
