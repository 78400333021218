import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import useI18n from '../utils/useI18n';
import Link from './Link';
import '../styles/components/_components.menu.scss';
import Search from './Search';

export default function Menu() {
  const { t } = useI18n();
  const [visible, setVisible] = useState(false);
  const data = useStaticQuery(graphql`
    query MenuCategoriesQuery {
      categories: allCategory {
        nodes {
          Category {
            id
            slug
            nombre
          }
        }
      }
    }
  `);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <div className={`c-menu ${!visible || 'c-menu--visible'}`}>
      <span className="c-menu__backdrop" onClick={toggleVisible} />
      <ul className="c-menu__links">
        <li>
          <Link to="/" activeClassName="current">
            {t('menu.home')}
          </Link>
        </li>
        <li>
          <Link to={`${t('url.about')}/`} activeClassName="current">
            {t('menu.about')}
          </Link>
        </li>
        <li>
          <Link to={`${t('url.blog')}/`} activeClassName="current" partiallyActive>
            {t('menu.blog')}
          </Link>
          <ul>
            {data.categories.nodes.map(({ Category }) => (
              <li key={Category.id}>
                <Link
                  to={`${t('url.blog')}${t('url.category')}/${Category.slug}/`}
                  activeClassName="current"
                  partiallyActive
                >
                  {Category.nombre}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link to={`${t('url.blacklist')}/`} activeClassName="current" partiallyActive>
            {t('menu.blacklist')}
          </Link>
        </li>
        <li>
          <Link to={`${t('url.faqs')}/`} activeClassName="current">
            {t('menu.faqs')}
          </Link>
        </li>
        <li>
          <Link to={`${t('url.testimonials')}/`} activeClassName="current" partiallyActive>
            {t('menu.testimonials')}
          </Link>
        </li>
        <li>
          <Link to={`${t('url.contact')}/`} activeClassName="current">
            {t('menu.contact')}
          </Link>
        </li>
        <li>
          <Search />
        </li>
      </ul>
      <div className="c-menu__mobile">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className="c-menu__trigger" onClick={toggleVisible}>
          <MenuOutlined /> Menu
        </button>
        <Search />
      </div>
      <button className="c-menu__close" onClick={toggleVisible}>
        <CloseOutlined /> Close
      </button>
    </div>
  );
}
